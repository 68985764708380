//HomePageSlider.jsx

import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function HomePageSlider() {
	return (
		<Carousel width="480px" autoPlay interval="1500" showThumbs={false} showIndicators={false} transitionTime="1000" infiniteLoop={true}>
                <div>
                    <img src="../assets/img/1.jpg" alt='' />
                    <p className="legend">Charminar</p>
                </div>
                <div>
                    <img src="../assets/img/2.jpg" alt='' />
                    <p className="legend">Golkonda Fort</p>
                </div>
                <div>
                    <img src="../assets/img/3.jpg" alt='' />
                    <p className="legend">Makkah Masjid</p>
                </div>
                <div>
                    <img src="../assets/img/4.jpg" alt='' />
                    <p className="legend">Salarjung Museum</p>
                </div>
                <div>
                    <img src="../assets/img/5.jpg" alt='' />
                    <p className="legend">Birla Mandir</p>
                </div>
                <div>
                    <img src="../assets/img/6.jpg" alt='' />
                    <p className="legend">Qutub Shahi Tombs</p>
                </div>
                <div>
                    <img src="../assets/img/7.jpg" alt='' />
                    <p className="legend">Chowmahalla Palace</p>
                </div>
                <div>
                    <img src="../assets/img/8.jpg" alt='' />
                    <p className="legend">Taramati Baradari</p>
                </div>
                <div>
                    <img src="../assets/img/9.jpg" alt='' />
                    <p className="legend">Falaknuma Palace</p>
                </div>
            </Carousel>
	)
}
