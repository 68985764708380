//Header.js

import React from "react";

export default function Header() {
	return (
		<div>
			<nav className="navbar navbar-expand-lg navbar-dark journal-header">
				<div className="container-fluid">
					<a className="navbar-brand title poppins-medium" href="#">City of Pearls</a>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="main_nav">
						<ul className="navbar-nav">
							<li className="nav-item active"> <a className="nav-link" href="#">Home </a> </li>
							<li className="nav-item dropdown">
								<a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">Places to Visit</a>
								<ul className="dropdown-menu">
									<li><a className="dropdown-item" href="#">50 Best Places to Visit in Hyderabad</a></li>
									<li><a className="dropdown-item" href="#">Top 9 Trekking Places near Hyderabad</a></li>
									<li><a className="dropdown-item" href="#">Popular Picnic Spots in Hyderabad</a></li>
									<li><a className="dropdown-item" href="#">Theme Parks in Hyderabad</a></li>
								</ul>
							</li>
							<li className="nav-item dropdown">
								<a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">Hyderabad Food</a>
								<ul className="dropdown-menu">
									<li><a className="dropdown-item" href="#">Top 10 Biryanies in Hyderabad</a></li>
									<li><a className="dropdown-item" href="#">10 best buffets</a></li>
									<li><a className="dropdown-item" href="#">10 Best Haleem Places in Hyderabad</a></li>
									<li><a className="dropdown-item" href="#">Top 20 Best Coffee Shops in Hyderabad</a></li>
								</ul>
							</li>
							<li className="nav-item dropdown">
								<a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">Shopping</a>
								<ul className="dropdown-menu">
									<li><a className="dropdown-item" href="#">10 Best Shopping Malls</a></li>
									<li><a className="dropdown-item" href="#">Top 5 Wholesale Markets for Dry Fruits</a></li>
									<li><a className="dropdown-item" href="#">Top 5 Wholesale Markets for Stationery</a></li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	)
}
