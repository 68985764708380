//App.js

import Header from './components/Header.js';
import HeroSection from './components/HeroSection';
import Cards from './components/Cards.js';
import { data } from './data.js';
import Footer from './components/Footer.js';
import './App.css';

function App() {
	const mappedData = data.map((item) => {
		return (
			<Cards key={item.id} item={item} />
		)
	})
	return (
		<div className="App">
			<Header />
			<HeroSection />
			<div class="container cardsContainer">
				<h3 className='heading-h3 poppins-medium pb-5'>Experience History, Culture, and Nature</h3>
				<div className='row'>
				{mappedData}
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default App;
