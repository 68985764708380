//HeroSection.jsx

import React from "react";
import HomePageSlider from "./HomePageSlider";
import { MapPinned } from "lucide-react";
import { quotes } from '../quotes';

export default function HeroSection() {
    const mappedData = quotes.map((item) => {
		return (
            <p className="quote_para" key={item.id}>{item.quote}</p>
		)
	})
    // const quotesScroller = () => {

    // }
	return (
        <div className="container-fluid" style={{backgroundColor: "#FFF7F5"}}>
		<div className="container hero_section">
			<div className="quotes">
                {/* <img src="../assets/img/quotation-mark.png" /> */}
                <MapPinned color="#7b7474" size={32} />
                <h5>History of Hyderabad</h5>
                {mappedData}
            </div>
            <div className="imagegallery">
                <HomePageSlider />
            </div>
		</div>
        </div>
	)
}
