//data.js

export const data = [
	{
		id: 1,
		date: "May 2018",
		title: "Charminar",
		description:
			"The busiest and most built-up lakeside town, Panajachel ('Pana') is the gateway to Lago de Atitlán for most travelers.",
		coverImg: "../assets/img/1.jpg",
		country: "GUATEMALA",
		googleMapLink: "https://maps.app.goo.gl/BhKMyaDxMGh5r1e4A",
		category: "Architectural Icon of Hyderabad"
	},
	{
		id: 2,
		date: "December 2019",
		title: "Golkonda Fort",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/2.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "12th Century Hilltop Fort"
	},
	{
		id: 3,
		date: "May 2018",
		title: "Ramoji Film City",
		description:
			"The busiest and most built-up lakeside town, Panajachel ('Pana') is the gateway to Lago de Atitlán for most travelers.",
		coverImg: "../assets/img/11.jpg",
		country: "GUATEMALA",
		googleMapLink: "https://maps.app.goo.gl/BhKMyaDxMGh5r1e4A",
		category: "Historical Landmark"
	},
	{
		id: 4,
		date: "December 2019",
		title: "Wonderla",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/12.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "Historical Landmark"
	},
	{
		id: 5,
		date: "May 2018",
		title: "Birla Mandir",
		description:
			"The busiest and most built-up lakeside town, Panajachel ('Pana') is the gateway to Lago de Atitlán for most travelers.",
		coverImg: "../assets/img/5.jpg",
		country: "GUATEMALA",
		googleMapLink: "https://maps.app.goo.gl/BhKMyaDxMGh5r1e4A",
		category: "Historical Landmark"
	},
	{
		id: 6,
		date: "December 2019",
		title: "Hussain Sagar Lake",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/14.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "Asia's Largest Artificial Lake"
	},
	{
		id: 7,
		date: "May 2018",
		title: "Nehru Zoo Park",
		description:
			"The busiest and most built-up lakeside town, Panajachel ('Pana') is the gateway to Lago de Atitlán for most travelers.",
		coverImg: "../assets/img/13.jpg",
		country: "GUATEMALA",
		googleMapLink: "https://maps.app.goo.gl/BhKMyaDxMGh5r1e4A",
		category: "A Popular Picnic Spot"
	},
	{
		id: 8,
		date: "December 2019",
		title: "Statue of Equality",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/10.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "Historical Landmark"
	},
	{
		id: 9,
		date: "December 2019",
		title: "Makkah Masjid",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/3.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "Historical Landmark"
	},
	{
		id: 10,
		date: "December 2019",
		title: "Salarjung Museum",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/4.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "Masterpieces from the World of Art"
	},
	{
		id: 11,
		date: "December 2019",
		title: "Qutub Shahi Tombs",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/6.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "A Famous Historic Landmark"
	},
	{
		id: 12,
		date: "December 2019",
		title: "Chowmahalla Palace",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/7.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "A Royal Heritage Monument"
	},
	{
		id: 13,
		date: "December 2019",
		title: "Taramati Baradari",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/8.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "Historical Landmark"
	},
	{
		id: 14,
		date: "December 2019",
		title: "Falaknuma Palace",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/9.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "A Royal Heritage Monument"
	},
	{
		id: 15,
		date: "December 2019",
		title: "Chilkur Balaji Temple",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/17.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "Historical Landmark"
	},
	{
		id: 16,
		date: "December 2019",
		title: "Birla Science Museum",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/15.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "Museum"
	},
	{
		id: 17,
		date: "December 2019",
		title: "Shilparamam",
		description:
			"Experience the beautiful landscape and meet new friends all while enjoying pristine warm waves of the Atlantic.",
		coverImg: "../assets/img/16.jpg",
		country: "BELIZE",
		googleMapLink: "https://maps.app.goo.gl/GvgSNEmWxuVXWvBV8",
		category: "Museum"
	}
]
