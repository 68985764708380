//Footer.js

import React from 'react';
import { Facebook, Twitter, Linkedin, Instagram } from "lucide-react";

export default function Footer() {
	return (
		<div className='journal-footer'>
			<div className='container'>
				<div className='col-md-4 footerMenu'>
					<h3>Menu</h3>
					<ul>
						<li>Home</li>
						<li>About Us</li>
						<li>Contact Us</li>
					</ul>
				</div>
				<div className='col-md-4 footerSocial'>
					<h3>Social</h3>
					<div className='socialLinks'>
					<Facebook />
					<Twitter />
					<Linkedin />
					<Instagram />
					</div>
				</div>
				<div className='col-md-4 footerAbout'>
					<h3>About Hyderabad</h3>
					<p>Hyderabad, a city located in the southern part of India, has a rich and diverse history that spans several centuries.</p>
				</div>
			</div>
			<caption className='copyrights'>© 2024 All Rights Reserved. <a href='/'>Exploring Hyderabad</a></caption>
		</div>
	)
};
