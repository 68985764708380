//Cards.js

import React from 'react';
import { MapPin } from "lucide-react";


export default function Cards(props) {
	return (
		<div className='col-md-3 card'>
			<img class="rounded d-block cardImg" src={props.item.coverImg} alt={props.item.title} />			
				{/* <span className="card-country">{props.item.country} </span> */}
				<h5 className="card-title poppins-medium">{props.item.title}</h5>
				{/* <h4 className="card-date">{props.item.date}</h4> */}
				<p className='card-category poppins-medium'>{props.item.category}</p>
				{/* <p>{props.item.description}</p> */}
				{/* <a href={props.item.googleMapLink} target='_blank' rel="noreferrer"><MapPin /> View on Google Maps</a> */}
		</div>
	)
}
